<template>
    <div class="flex flex-col pl-2 items-start justify-center">
        <NuxtImg 
            src="/images/main/logo.png"
            class="h-10 w-24" 
            alt=""
             />
        <p class="text-xs mx-auto font-semibold tracking-widest">
            Dashboard
        </p>
    </div>
</template>
<script>
</script>
<style lang="">
    
</style>