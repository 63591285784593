<template>
    <div class="flex flex-col w-full m-0 p-0 z-30 sticky top-0 bg-transparent shadow">
      <div class="flex flex-none w-full h-20 mx-auto pt-2 z-50 bg-white">
        <div class="flex w-full items-center max-w-screen-2xl mx-auto">
            <Icon @click="drawerStore.openDrawer()" name="streamline:interface-setting-menu-2-button-parallel-horizontal-lines-menu-navigation-staggered-three-hamburger" class="w-2/12 text-2xl mx-2"/>
            <DashboardNavBrandSection class="w-6/12" />
            <DashboardCreateNewDropDown class="w-4/12"/>
        </div>
      </div>
      <BaseAlert alignText="center"
                 class="z-40" />
    </div>
  </template>
  <script setup lang="ts">
import { useDrawer } from '~/stores/Drawer';
const drawerStore = useDrawer();
</script>
  