<template>
    <div class="flex flex-row items-center justify-center text-sm">
        <nuxt-link :to="{ name: 'index' }"
                   class="flex flex-row items-baseline px-4 font-semibold hover:text-a-secondary focus:outline-none">
            Home
        </nuxt-link>
        <nuxt-link :to="{ name: 'dashboard' }"
                   class="flex flex-row items-baseline px-4 font-semibold hover:text-a-secondary focus:outline-none">
            Dashboard
        </nuxt-link>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="">
    
</style>