<template>
  <div class="flex flex-col w-full m-0 p-0 max-w-screen-2xl z-30 sticky top-0 bg-transparent shadow">
    <div class="flex flex-none w-full px-0 md:px-2 lg:px-6 h-20 mx-auto pt-2 z-50 bg-white">
      <div class="flex w-full max-w-screen-2xl mx-auto">
        <DashboardNavBrandSection class="w-1/12" />
        <DashboardNavMenuSection class="w-2/12" />
        <div class="w-5/12"></div>
        <div class="flex flex-row-reverse w-4/12 items-center">
          <BaseUserDropdown />
          <DashboardCreateNewDropDown />
        </div>
      </div>
    </div>
    <BaseAlert alignText="center" class="z-40" />
  </div>
</template>
<script setup lang="ts"></script>
