<template>
  <div
      class="flex relative mr-4"
      v-click-outside="() => {
             showCreateNewDropdown = false;
         }
             "
  >
    <div
        class="flex flex-row items-center text-sm w-44 h-8 justify-center cursor-pointer select-none bg-a-primary text-a-neutral-darkest rounded shadow-lg"
        @click.native="
                 showCreateNewDropdown =
                 !showCreateNewDropdown
                 "
    >
                 <span class="hidden md:flex">
                     <Icon
                         name="ic:outline-add"
                         class="text-xl"
                     />
                    </span>
      <p class="font-semibold">
        Create New
      </p>
      <Icon
          :name="showCreateNewDropdown
                ? 'tabler:chevron-up'
                : 'tabler:chevron-down'"
          class="text-xl"
      />
    </div>
    <div
        v-show="showCreateNewDropdown
            "
        class="flex flex-col absolute -bottom-40 right-40 w-52 text-sm border p-2 left-0 px-1 bg-white rounded shadow-lg transition-opacity duration-500 ease-out"
    >
      <div
          class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none"
          @click="gotoCreateNew('dashboard-businesses-create-new')"
      >
        Business Listing
      </div>
      <div
          class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none"
          @click="gotoCreateNew('dashboard-classified-create-new')"
      >
        Classified Ad Listing
      </div>
      <div
          class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none"
          @click="gotoCreateNew('dashboard-fundraising-create-new')"
      >
        Fundraiser
      </div>
      <div
          class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none"
          @click="gotoCreateNew('dashboard-news-create-new')"
      >
        News Announcement
      </div>
      <div
          class="hover:bg-a-secondary hover:text-white px-2 py-1 cursor-pointer rounded select-none"
          @click="gotoCreateNew('dashboard-islamic-centers-create-new')"
      >
        Islamic Center Listing
      </div>
    </div>
  </div>
</template>
<script
    setup
    lang="ts"
>
const showCreateNewDropdown =
    ref(false);

function gotoCreateNew(routeName) {
  showCreateNewDropdown.value = false
  navigateTo({
    name: routeName
  })
}
</script>